<template>
   
   <nav id="main-nav">
         <ul class="second-nav">
            <li class="osahan-user-profile bg-primary">
               <div class="d-flex align-items-center gap-2">
                  <img src="img/delivery-boy.jpg" alt="" class="rounded-pill img-fluid">
                  <div class="ps-1">
                     <h5 class="fw-bold text-white osahan-mb-1">{{(user)?user.name:'Guest'}}</h5>
                     <p class="text-white-50 m-0">{{(user)?user.number:''}}</p>
                  </div>
               </div>
            </li>
            <li><router-link to="/home"><i class="bi bi-house me-3"></i>Homepage</router-link></li>
            <li>
               <a href="#"><i class='bi bi-link me-3'></i>Categories</a>
               <ul>
                  <li v-for="item in cats" :key="item.in"><router-link :to="'/listing?id='+item.id">{{item.name}}</router-link></li>
                  
               </ul>
            </li>

            <li >  <a   @click="logout" class="link-dark"><i  class="bi bi-box-arrow-right me-3"></i>logout</a></li>
         </ul>
         <ul class="bottom-nav">
            <li class="home">
               <router-link to="/home">
                  <p class="h5 m-0"><i class="bi bi-house"></i></p>
                  Home
               </router-link>
            </li>
            <li class="cart">
               <router-link to="/cart">
                  <p class="h5 m-0"><i class="bi bi-basket"></i></p>
                  Cart
               </router-link>
            </li>
            <li class="profile">
               <router-link to="/profile">
                  <p class="h5 m-0"><i class="bi bi-person"></i></p>
                  Profile
               </router-link>
            </li>
         </ul>
      </nav>
     
</template>
 
<script>
export default {
  name: 'Sidebar',
   props:
      {
        cats:Array, user:Array

      },
      data() {
      return {
         isloaded:0
      }
   },
   updated() {
   if(this.isloaded==0){
      $('#main-nav').hcOffcanvasNav({
               disableAt: 1024,
               customToggle: $('.toggle'),
               navTitle: 'All Categories',
               levelTitles: true,
               levelTitleAsBack: true
            });
    
   }
   this.isloaded=1;
},
methods: {
   logout(){
      localStorage.removeItem('token');
      this.$router.push({ name: "/"});
   }
}
  
}
</script>
