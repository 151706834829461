<template>
   <div class="listing d-flex flex-column vh-100">
      <div class="bg-white mb-auto">
         <div class="d-flex align-items-center gap-3 p-3 bg-primary">
            <a @click="$router.go(-1)" class="text-white"><i class="bi bi-arrow-left fs-5"></i></a>
            <div>
               <h6 class="fw-bold text-white mb-0">{{heading}}</h6>
               <p class="text-white-50 small m-0">Healthline Distributers</p>
            </div>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <router-link  :to="(isVerified)?'/profile':'/'" class="link-dark">
                  <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i
                        class="bi bi-person d-flex m-0 h4 text-white"></i></div>
               </router-link>
               
            </div>
         </div>

      </div>
      <!-- body -->
      <div class="vh-100 my-auto overflow-auto p-1"   ref="scrollContainer">
         <div class="pt-3">
            
            <ProductItems :productslist="ProductList" :catimg="cat_img" :isVerified="isVerified" @addtocart="addtocart"/>
         </div>
      </div>
</div>
<div v-if="varationshow == 1">
      <div class="offcanvas offcanvas-bottom border-0 h-50 show" tabindex="-1" id="variationoffcanvas"
         aria-labelledby="variationoffcanvasLabel">
         <div class="offcanvas-header bg-primary">
            <div class="input-group bg-white rounded-3 border-0 p-0">
               <h5 class="w-80 p-2">Select Options</h5>
               <a @click="variationoff(cur_item)" class="input-group-text bg-transparent border-0 rounded-0" id="search"
                  data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-circle  fs-5"></i></a>
            </div>
         </div>
         <div class="offcanvas-body p-0">
            <div class="bg-white shadow-sm p-3 border-bottom border-top"  v-for="item in variation" :key="item.id">
               <div class="form-check form-check-reverse">
                  <label class="form-check-label d-flex align-items-center gap-2" for="exampleRadios1">
                     <i class="icofont-cart  icofont-3x text-primary"></i> <span class="var_name">{{item.name}}  (₹ {{item.price}})</span>
                     <div class="text-start">
                  <div
                     class="osahan-count d-flex align-items-center justify-content-between border border-dark-subtle rounded-pill h6 m-0 p-1">
                     <span class="text-muted minus d-flex" @click="decreaseQuantity(item)"><i class="icofont-minus-circle"></i></span>
                     <input type="text" class="lh-sm small text-black text-center box border-0" v-model="item.quantity" >
                     <span class="text-muted plus d-flex" @click="increaseQuantity(item)"><i class="icofont-plus-circle"></i></span>
                  </div>
               </div>
               </label>
               </div>
            </div>
          

            <div class="row g-0">
               <div class="col-12 border-bottom">
                  <h6 class="p-3 m-0">Total Cart Items <span class="fw-bold">"{{cart_cnt}}"</span></h6>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <button class="btn btn-success w-100 text-uppercase btn-lg fw-bold" @click=addvatiation_tocart()>Add To Cart</button>
            </div>

         </div>
      </div>
      <div class="offcanvas-backdrop fade show"></div>
   </div>
      <BottomMenu  :cart_cnt="cat_items_cont" :isVerified="isVerified"/>

      <Canvases :name="name" :categoryData="categoryData"  :sucbfoodData="sucbfoodData" />
      <Sidebar  :cats="categoryData"/>
</template>
<script>


// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import ProductItems from '@/components/ProductItems.vue'
import Header from '@/components/Header.vue'

import axios from 'axios';
import { mask } from 'vue-the-mask'

const config = {
   headers: {
      "Content-Type": "application/x-www-form-urlencoded"
   },
   responseType: 'json'
};
export default {
   name: 'listing',
   components: {
      Sidebar, Canvases, BottomMenu,ProductItems,Header
   },
   data() {
      return {
         ProductList: [],
         variationData: [],
         sucbfoodData: [],
         cat_name: '',
         cat_img: '',
         categoryData: [],
         SelectedItem: [],
         cat_items_cont: 0,
         isVerified:0,
         var_item_array:[],
         cur_item:[],
         var_qty:0,
         heading:'',
         variation:[],
         scrollPosition: 0,
         varationshow:0
      }
   }, 
   created() {
      if (this.$route.query.id) {
         this.getCategoryItems(this.$route.query.id);
      }
   },
   methods: {
      addvatiation_tocart() {
         var self=this;
         axios.post('ajaxAddVariationToCart', { data: this.var_item_array, token: ((localStorage['token']) ? localStorage['token'] : '') }).then(response => {
            var status = response.data.msg;
              self.$swal(status);
            
            self.cat_items_cont = response.data.total_qty;
            self.varationshow = 0;

         }).catch(error => {
            console.error(error);
         });
      },
      increaseQuantity(item) {
         this.var_item_array[item.id] = item;
         this.cur_item = item;
         item.quantity++;
      },
      decreaseQuantity(item) {
         if (item.quantity >= 0) {
            this.var_item_array[item.id] = item;
            this.cur_item = item;
            item.quantity--;
         }
      },
      addtocart(id,varcnt) {
                              var self=this;
         if(!localStorage['token']){
            this.$swal.fire({
                  title: "Please Register To Purchase",
                  showCancelButton: true,
                  confirmButtonText: "Register" 
                  }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                     this.$router.push({ name: "registration", });
                  }  
                  });
            //
         }else{ 
                     if(varcnt>0){
                              axios.post('getAjaxVariation', {prd_id: id,token:((localStorage['token'])?localStorage['token']:'')}).then(response => {
                                 var status = response.data.msg;
                                          if(status.toLowerCase().indexOf("n") != -1 || status.indexOf("Pending") != -1)
                                             self.$swal( status);
                                          else{
                                                         self.variation = response.data.varlist;
                                                         self.varationshow = 1;
                                          }
                                                
                                       } ).catch(error => {
                                          console.error(error);
                                       });
                           }else{
                              axios.post('ajaxAddToCart', {prd_id: id,variation:0,token:((localStorage['token'])?localStorage['token']:'')}).then(response => {
                                            self.cat_items_cont= response.data.total_qty; 
                                            var msg= response.data.msg; 
                                            self.$swal(msg);

                                       } ).catch(error => {
                                          console.error(error);
                                       });
                           }
                  
               
            }

        
      },
      variationoff(cur_item) {
         this.varationshow = 0;
         this.var_item_array = this.var_item_array.filter(function( obj ) {
               return obj.food_id !== cur_item.food_id;
               });
          
      },
      showsidebar() {
         $("#sidebar").show();
      },
      save_in_cart() {
         var self = this;
         axios.get('')

      },
      getCategoryItems(id) {
         var self = this;
         id=decodeURIComponent(id);
         if($.isNumeric(id)){
            axios.post('getCmpItems', {cat_id: id,token: ((localStorage['token']) ? localStorage['token'] : '') }).then(function (response) {
            self.ProductList = response.data.product;
            self.cat_name = response.data.cat.name;
            self.cat_img = response.data.cat.img;
            self.cat_items_cont = response.data.cart_cnt;
            self.heading= response.data.cat.name;
            self.categoryData = response.data.categorylist;
            self.isVerified=response.data.isVerified;
         }).then(()=>{
            if(self.scrollPosition)
                self.$refs.scrollContainer.scrollTop = self.scrollPosition;
         }); 
         }else{
            axios.post('getSearchItems', {searchtxt: id,token: ((localStorage['token']) ? localStorage['token'] : '') }).then(function (response) {
            self.ProductList = response.data.product;
            self.cat_name = response.data.cat.name;
            self.cat_img = response.data.cat.img;
            self.cat_items_cont = response.data.cart_cnt;
            self.isVerified=response.data.isVerified;
            self.heading= "Search Result : "+id;
         }).then(()=>{
            if(self.scrollPosition)
                self.$refs.scrollContainer.scrollTop = self.scrollPosition;
         }); 
         }
         
      },

      getProductImageUrl(img) {
         return `${this.$baseurl}public/uploads/food/${img}`;
      }
      ,
      getImageUrlCat(img) {
         return `${this.$baseurl}public/uploads/category/${img}`;
      }, 
      product_details(id) {
         var self = this;
         self.$router.push({ name: "product_details", query: { id } });
      },

      saveScrollPosition() {
         this.scrollPosition = this.$refs.scrollContainer.scrollTop;
         localStorage["scrollPosition"]=this.scrollPosition;
         console.log('beforeRouteLeave called'+this.scrollPosition);
      },
      restoreScrollPosition() {
         this.$nextTick(() => {
         this.scrollPosition=  localStorage["scrollPosition"];
         localStorage["scrollPosition"]=0;
         
         console.log('restoreScrollPosition called'+this.scrollPosition);
         });
      },
   },
   beforeRouteLeave(to, from, next) {
      this.saveScrollPosition();
      next();
   },
   beforeRouteEnter(to, from, next) {
      next(vm => {
         vm.restoreScrollPosition();
      });
   },
}
</script>
